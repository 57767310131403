import merge from "webpack-merge";
import baseConfig from "./config.base";

const config = {
  hosts: {
    resource: "https://test.adinghuo.com/",
    request: "https://testback.adinghuo.com/",
    analysis:"https://testanalyback.adinghuo.com/",
  },
};

export default merge(baseConfig, config);
